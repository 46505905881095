/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link, Route , withRouter} from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Alert,
} from "reactstrap";
import { isConstructorDeclaration } from "typescript";
import axios from 'axios';
import {Helmet} from "react-helmet";
class Login extends React.Component {
  
  state= {
    username:'',
    userpassword:'',
    loginfail:false,
    remembermecheck:false,
    errorsubmit:false,
    alerterrormessage:'',
    linkurl:"https://crm.mewahtrans.com.my/api/mewahapicode.php?",
   // imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
    //linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    imageurllink:"https://crm.mewahtrans.com.my/api/img/profile_img/",
  }
  // 111callloginapifunction = () =>{

  


  //   var that = this;
  //   var date = new Date().getDate(); //Current Date
  //   var twodigitdate = ("0"+date).slice(-2);
  //   var month = new Date().getMonth() + 1; //Current Month
  //   var twodigitmonth = ("0"+month).slice(-2);
  //   var year = new Date().getFullYear(); //Current Year
  //   var hours = new Date().getHours(); //Current Hours
  //   var twodigithour = ("0"+hours).slice(-2);
  //   var min = new Date().getMinutes(); //Current Minutes
  //   var twodigitmin = ("0"+min).slice(-2);
  //   var sec = new Date().getSeconds(); //Current Seconds
  //   var dateformat = year + '-' + twodigitmonth +'-'+twodigitdate +'T' + twodigithour+':'+twodigitmin+':'+'00+08:00'
  //   //this.refs.toast.show(dateformat)
  //   let username =this.state.username
  //   let password = this.state.password
  //   let tokentext = 'ti@nsi' + 'm32|r33' + username.toLowerCase()+'|' + Date.parse(dateformat)/1000
  //   let token2 = md5.hex_md5(tokentext);
  //   var data = new FormData();
  //   let md5password = md5.hex_md5(password);
  //   data.append("token",token2)
  //   data.append("employeeid",username)
  //   data.append("employeepassword",md5password)
  //   let link = 'http://winnercloudstudio.com/api_employee.php?mode=admin-login'
  //   //this.refs.toast.show(tokentext)
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     body: data,
  //   })
  //   .then(response => response.json())
  //   .then(json => {

  //     if(json.success == 1){
  //       if(this.state.rememberMe === true){
  //         this.rememberUser();
  //        }else{
  //          this.forgetUser();
  //        }
  //       var lengthemployee = Object.keys(json.employee).length;
  //       for(let i=0;i<lengthemployee;i++){
  //         if(json.employee[i].employeelevel != 'Admin'){
  //           this.refs.toast.show('Employee cannot login this 2app');
  //         } else{            
  //           global.EmployeeRef=json.employee[0].Ref;
  //           global.EmployeeName =json.employee[0].employeename;
  //           global.EmployeePhone =json.employee[0].employeephone;
  //           global.EmployeeLevel =json.employee[0].employeelevel;
  //           global.EmployeeWeekScore =json.employee[0].WeekPoint;
  //           global.EmployeeMonthScore=json.employee[0].MonthPoint;
  //           global.EmployeeYearPoint =json.employee[0].YearPoint;
  //           global.EmployeeRace =json.employee[0].employeerace;
  //           global.EmployeeGender =json.employee[0].gender;
  //           global.EmployeeEmployeeID =json.employee[0].employeeid;
  //           global.EmployeeProfileUrl =json.employee[0].profileimageurl;
  //           global.EmployeeTokenNotification =json.employee[0].NotificationToken;
  //           if (json.employee[0].Branch == 1){
  //             global.EmployeeBranch = 'Giant';
  //           } else if(json.employee[0].Branch == 2){
  //             global.EmployeeBranch = 'Mydin'
  //           } else if(json.employee[0].Branch == 3){
  //             global.EmployeeBranch = 'Austin'
  //           }
                  
  //           this.props.navigation.navigate('Profile');
  //         }        
  //       }      
  //     } else{
  //      this.refs.toast.show('Wrong Username and Password');
  //      //this.refs.toast.show(Date.now());
  //     }    
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
  //   //this.refs.toast.show(this.state.jsonData)
  //   //() => this.props.navigation.navigate('Profile')
  // }./../assets/img/brand/logomewah4.png
  
  onloadchecklocalstoragerememberme = () =>{
  
    if(localStorage.getItem('Mewahtransremembermeusername') != null){
      this.setState({username:localStorage.getItem('Mewahtransremembermeusername')});
      this.setState({userpassword:localStorage.getItem('Mewahtransremembermepwd')});
      this.setState({remembermecheck:true})
    }
  }
  
  componentDidMount(){
    this.onloadchecklocalstoragerememberme()
  }


  Loginbuttonclick = () => {
    this.setState({errorsubmit:false})
    console.log(this.state.username);
    console.log(this.state.userpassword);
    var urlencoded = new URLSearchParams();
    urlencoded.append("username", this.state.username);
    urlencoded.append("userpassword", this.state.userpassword);
     let link = this.state.linkurl + 'mode=admin-login'
    
   
    fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Origin': '*',
          },
          body:urlencoded
        })
        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){

            if(json.user[0].Status == 2){
              if(this.state.remembermecheck == true){
                localStorage.setItem("Mewahtransremembermeusername",json.user[0].Username)
                localStorage.setItem("Mewahtransremembermepwd",json.user[0].Passworduser)
              }
              if(json.user.length > 1){
                //have 2 account
                //show profile there can switch account to BillName2, secondbilltoparty
                sessionStorage.setItem('have2account', 1);
                sessionStorage.setItem('Billtopartyaccount2', json.user[1].Billtoparty);
                sessionStorage.setItem('Usernameaccount2', json.user[1].Username);
                sessionStorage.setItem('Nameaccount2', json.user[1].Name);
                sessionStorage.setItem('Refaccount2', json.user[1].Ref);
                sessionStorage.setItem('Levelaccount2', json.user[1].Level);
                sessionStorage.setItem('Billtopartyaccount2', json.user[1].Billtoparty);
                sessionStorage.setItem('Directorforwarderaccount2', json.user[1].Directorforwarder);
                sessionStorage.setItem('Emailaccount2', json.user[1].Email);
                sessionStorage.setItem('Contactaccount2', json.user[1].Contactnumber);
                sessionStorage.setItem('Profilepictureurlaccount2', json.user[1].Profilepictureurl);
              }else{
                sessionStorage.setItem('have2account', 0);
                sessionStorage.setItem('secondbilltoparty', '');
              }
              sessionStorage.setItem('Username', json.user[0].Username);
              sessionStorage.setItem('Name', json.user[0].Name);
              sessionStorage.setItem('Ref', json.user[0].Ref);
              sessionStorage.setItem('Level', json.user[0].Level);
              sessionStorage.setItem('Billtoparty', json.user[0].Billtoparty);
              sessionStorage.setItem('Directorforwarder', json.user[0].Directorforwarder);
              sessionStorage.setItem('Email', json.user[0].Email);
              sessionStorage.setItem('Contact', json.user[0].Contactnumber);
              sessionStorage.setItem('Profilepictureurl', json.user[0].Profilepictureurl);

                this.props.history.push({
                  pathname: '/admin/dashboard',
                  state: {
                    id: 0,
                  }
                })
            }else if(json.user[0].Status == 1){
              this.setState({errorsubmit:true})
              this.setState({alerterrormessage:"Your account is pending approval"})
            }else if(json.user[0].Status == 3){
              this.setState({errorsubmit:true})
              this.setState({alerterrormessage:"Your account is rejected"})
            }
              
               
                     
            } else{
            
            this.setState({errorsubmit:true})
            this.setState({alerterrormessage:"Something wrong, please try again"})
            console.log(json)
          }
        
         })

 

  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
}
  
checktbefunction = () =>{  
 
  this.setState({ remembermecheck: !this.state.remembermecheck}) 
  console.log("remembermecheck:" + this.state.remembermecheck)
}


  render() {
    return (
      <>
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Mewahtrans Logistic Sdn Bhd</title>
            </Helmet>
        </div>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign In</small>
              </div>
              {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name='username' value={this.state.username} onChange={e => this.handleChange(e)} placeholder="Username/Email" type="text" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input name='userpassword' value={this.state.userpassword} onChange={e => this.handleChange(e)} placeholder="Password" type="password" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                    className="custom-control-input"
                    id="customCheck2"
                    type="checkbox"
                    disabled={false}
                    checked={this.state.remembermecheck}
                    onChange={(e)=> this.checktbefunction() }
                  />  
                     <label className="custom-control-label" htmlFor={"customCheck2"}>
                      Remember Me
                    </label>
                </div>
                </FormGroup>
                <div className="text-center">
                
                  <NavLink className="nav-link-icon" >
                  <Button className="my-4" color="primary" type="button" onClick={this.Loginbuttonclick.bind(this)}>
                    Sign in
                  </Button>
                  </NavLink>
                
                 
                </div>
                {this.state.loginfail ? <span  style={{color:'red'}}>Login fail, please try again.</span> : null}
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/forgetpassword"
                to="/auth/forgetpassword"
                tag={Link}
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
                to="/auth/register"
                tag={Link}
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
// console.log(this.state)


    // axios.post('https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-login',this.state)
    // .then(res => {
    //   console.log(res);
    //   console.log(res.data);
    // })
    // const options ={
    //   method:'POST',
    //   headers,
    //   body:{
    //     username:'wcwc',
    //     userpassword:'wcwc',
    //   },
    // }
    // const request = new Request('https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=admin-login',options);
    // const response = await fetch(request);
    // const status  = await response.success;

    // console.log(status)
    // console.log(response)
 //  fetch('https://jsonplaceholder.typicode.com/posts/1', {
    //   method: 'PUT',
    //   body: JSON.stringify({
    //     id: 1,
    //     title: 'foo',
    //     body: 'bar',
    //     userId: 1
    //   }),
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8"
    //   }
    // })
    // .then(response => response.json())
    // .then(json => console.log(json))
    
    //this.setState({username:'success'})
    
    // var mysql      = require('mysql');
    // var connection = mysql.createConnection({
    //   host     : '220.158.201.42',
    //   user     : 'winnercl_tiansi',
    //   password : 'huathuat1234',
    //   database : 'winnercl_tiansiapp',
    // });
    
    // connection.connect();
    
    // connection.query('SELECT * From TypeOfLeave', function (error, results, fields) {
    //   if (error) throw error;
    //   console.log('The result is: ', results[0].Ref);
    // });
    
    // connection.end();